import React from "react";

const OrderLineItemThumbnail = ({ thumbnail, skuId }) => {
  const src = thumbnail
    ? `https://${thumbnail}`
    : "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";

  return <img src={src} alt={skuId} style={{ width: "100%" }} />;
};

export default OrderLineItemThumbnail;
