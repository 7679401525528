import { gql } from "apollo-boost";

export const orderFragment = gql`
  fragment LineItemFields on LineItem {
    id
    skuId
    productId
    customerId
    orderId
    orderNo
    name
    variants
    unitPrice
    currency
    quantity
    amount
    thumbnail
    seller
    sellerPlatform
  }

  fragment OrderParcel on OrderParcel {
    id
    parcel {
      measurement {
        length
        width
        height
        weight
        chargeableWeight
      }
    }
  }

  fragment OrderShipping on Order {
    shipping {
      address {
        city
        country
        line1
        line2
        postcode
        state
        isDefault
      }
      courier
      name
      phone
      trackingNumber
    }
  }

  fragment OrderConsolidation on Order {
    consolidationOrder {
      id
      consolidationOrderNo
    }
  }

  fragment OrderFields on Order {
    id
    orderNo
    customerId
    warehouseId
    status
    ...OrderConsolidation
    ...OrderShipping
    amount
    value
    currency
    date
    createdAt
    updatedAt
    parcels {
      items {
        ...OrderParcel
      }
    }
    lineItems {
      items {
        ...LineItemFields
      }
      nextToken
    }
    warehouse {
      id
      country
      address
    }
  }
`;

export const GET_ORDER = gql`
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      ...OrderFields
    }
  }
  ${orderFragment}
`;

export const LIST_ORDERS = gql`
  query ListOrdersByStatus(
    $status: OrderStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...OrderFields
      }
      nextToken
    }
  }
  ${orderFragment}
`;

export const LIST_ALL_ORDERS = gql`
  query ListOrders(
    $orderNo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(
      _type: "MMUCHO_ORDER"
      orderNo: $orderNo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...OrderFields
      }
      nextToken
    }
  }
  ${orderFragment}
`;
