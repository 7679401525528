import dayjs from "dayjs";
import { navigate } from "gatsby";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useCurrencyCalc } from "../../../utils/currencyCalc";
import styles from "./css/orderCard.module.scss";
import OrderCard from "./OrderCard";

const ConsolidationOrderCard = (props) => {
  const { t } = useTranslation();

  const consolidationOrder = props.order && props.order;
  const id = consolidationOrder.id;
  const orderNo = consolidationOrder.consolidationOrderNo;
  const date = dayjs(consolidationOrder.createdAt).format("hh:mmA  DD/MM/YYYY");
  const amount = consolidationOrder.amount;
  const currency = consolidationOrder.currency;
  const price = useCurrencyCalc(amount, currency).result;
  const destination = consolidationOrder.shipping
    ? `${consolidationOrder.shipping.address.line1}, 
    ${consolidationOrder.shipping.address.line2}, 
    ${consolidationOrder.shipping.address.country}`
    : "";
  const status = consolidationOrder.status;
  const orders = consolidationOrder.orders.items;
  const link = `app/consolidationOrders/${orderNo}`;
  const [ordersOpen, setOrdersOpen] = useState(true);

  return (
    <>
      <tr className={styles.row} key={id}>
        {!props.hideDetailDisplay ? (
          <>
            {ordersOpen ? (
              <td onClick={() => setOrdersOpen(false)}>↑</td>
            ) : (
              <td onClick={() => setOrdersOpen(true)}>↓</td>
            )}
          </>
        ) : (
          <td>{t("orders.consolidationOrder")}:</td>
        )}
        <td>{orderNo}</td>
        <td>{date}</td>
        <td>{price}</td>
        <td>{destination}</td>
        <td>{status}</td>
        {props.hideDetailDisplay && <td />}
        {!props.hideDetailDisplay && (
          <td>
            <div
              onClick={() => {
                navigate(link, { state: { id: id, createdAt: date } });
              }}
              className={styles.button}
            >
              {t("orders.detail")}
            </div>
          </td>
        )}
      </tr>
      {ordersOpen && (
        <>
          {orders.map((order) => {
            return (
              <OrderCard
                order={order}
                isConsolidationOrder={props.isConsolidationOrder}
                compact={true}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default ConsolidationOrderCard;
