import { Spin } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {*} amount number
 * @param {*} base string (e.g. "AUD")
 * @param {*} qty number
 */

export const useCurrencyCalc = (amount, base, qty = 1) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [result, setResult] = useState(0);
  const [convertTo, setConvertTo] = useState("AUD");

  useEffect(() => {
    switch (language) {
      case "en":
        setConvertTo("AUD");
        break;
      case "zh-CN":
        setConvertTo("CNY");
        break;
      default:
        setConvertTo("AUD");
    }
  }, [language]);

  useEffect(() => {
    if (amount && base && convertTo) {
      fetch(`https://api.exchangeratesapi.io/latest?base=${base}`)
        .then((res) => res.json())
        .then((data) => {
          setResult((data.rates[convertTo] * amount) / 100);
        });
    }
  }, [convertTo, amount, base]);

  const formatCurrencySymbol = (convertTo) => {
    switch (convertTo) {
      case "AUD":
        return "$";
      case "CNY":
        return "¥";
      default:
        return "$";
    }
  };

  return {
    number: result * qty,
    result:
      result !== 0 ? (
        formatCurrencySymbol(convertTo) + (result * qty).toFixed(2)
      ) : (
        <Spin />
      ),
    currency: convertTo,
  };
};
