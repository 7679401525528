import Badge from "antd/lib/badge";
import Space from "antd/lib/space";
import Table from "antd/lib/table";
import Typography from "antd/lib/typography";
import React from "react";
import { useTranslation } from "react-i18next";

import { OrderStatus } from "../../../utils/constants";
import { formatCurrency, formatOrderRecipient } from "../../../utils/helpers";
import Thumbnail from "../Thumbnail";

const { Text, Paragraph } = Typography;

const OrderCard = ({ loading, order }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const renderTitle = () => (
    <Space>
      <Text strong>{order.date}</Text>
      <Text>
        {t(`orders.orderNo`)}: {order.orderNo}
      </Text>
      <Badge
        status={
          order.status === OrderStatus.ARRIVED_AT_WAREHOUSE
            ? "warning"
            : "success"
        }
        text={t(`orders.status.${order.status}`)}
      />
    </Space>
  );

  const dataSource = order.lineItems.items;

  const getRowKey = (record) => `${order.id}-${record.skuId}`;

  return (
    <Table
      bordered
      scroll={{ x: 1000 }}
      loading={loading}
      pagination={false}
      dataSource={dataSource}
      title={renderTitle}
      showHeader={false}
      rowKey={getRowKey}
      columns={[
        {
          width: "100px",
          key: "thumbnail",
          render: (record) => <Thumbnail {...record} />,
        },
        {
          width: "auto",
          key: "description",
          render: (record) => (
            <>
              <Paragraph strong>{record.name}</Paragraph>
              <Text>{record.variants.join(" | ")}</Text>
            </>
          ),
        },
        {
          width: "15%",
          dataIndex: "unitPrice",
          render: (unitPrice, record) =>
            formatCurrency(unitPrice, record.currency, language),
        },
        {
          width: "10%",
          dataIndex: "quantity",
        },
        {
          width: "15%",
          key: "amount",
          render: (_, _row, index) => ({
            children: formatCurrency(order.amount, order.currency, language),
            props: { rowSpan: index ? 0 : dataSource.length },
          }),
        },
        {
          width: "10%",
          key: "destination",
          render: (_, _row, index) => ({
            children: formatOrderRecipient(order),
            props: { rowSpan: index ? 0 : dataSource.length },
          }),
        },
      ]}
    />
  );
};

export default OrderCard;
