import { useQuery } from "@apollo/react-hooks";
import Spin from "antd/lib/spin";
import { Link } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

import { GET_CONSOLIDATION_ORDER } from "../../../graphql/consolidationOrder";
import OrderSummary from "../../checkout/OrderSummary";
import ConsolidationOrderCard from "./ConsolidationOrderCard";
import styles from "./css/orderDetail.module.scss";

const ConsolidationOrderDetail = ({ id }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_CONSOLIDATION_ORDER, {
    variables: { id },
  });

  const order = data?.getConsolidationOrder;

  return (
    <section className={styles.container}>
      {loading && <Spin tip={t("detail.loading")} />}
      {order && (
        <>
          <Link to="/app/orders">
            <p className={styles.link}>
              {"<<"} {t("detail.back")}
            </p>
          </Link>
          <OrderSummary order={order} isConsolidationOrder={true} />
          <ConsolidationOrderCard
            order={order}
            isConsolidationOrder={true}
            hideDetailDisplay={true}
          />
        </>
      )}
    </section>
  );
};

export default ConsolidationOrderDetail;
