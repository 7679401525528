import { gql } from "apollo-boost";

import { orderFragment } from "./order";

const consolidationOrderFragment = gql`
  fragment ConsolidationOrderFields on ConsolidationOrder {
    id
    consolidationOrderNo
    status
    customerId
    warehouseId
    shippingMethod {
      category
      type
    }
    shippingFee
    amount
    currency
    date
    createdAt
    updatedAt
    orders {
      items {
        ...OrderFields
      }
      nextToken
    }
  }

  ${orderFragment}
`;

export const GET_CONSOLIDATION_ORDER = gql`
  query GetConsolidationOrder($id: ID!) {
    getConsolidationOrder(id: $id) {
      ...ConsolidationOrderFields
    }
  }

  ${consolidationOrderFragment}
`;

export const LIST_CONSOLIDATION_ORDERS = gql`
  query ListConsolidationOrdersByStatus(
    $status: ConsolidationOrderStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsolidationOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsolidationOrdersByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...ConsolidationOrderFields
      }
      nextToken
    }
  }
  ${consolidationOrderFragment}
`;
